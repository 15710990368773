.counter {
  .counter-action {
    display: inline-block;
    width: 50px;
    box-shadow: 0px 1px 3px rgba(18, 31, 34, 0.06), 0px 4px 6px rgba(18, 31, 34, 0.08);

    button {
      &:focus {
        outline: none;
      }
    }

    &--right {
      border-radius: 0px 4px 4px 0px;
    }

    &--left {
      border-radius: 4px 0px 0px 4px;
    }
  }

  &--count {
    font-size: 20px;
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
    padding: 12px 20px;
  }
}
