@use '../../../assets/scss/variables.scss';

$text-muted: #6C7B7F;
$success-color: #51C133;

.payment-sumary {
  .total {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .label {
    font-weight: 600;
  }

  .sub-label {
    color: $text-muted;
  }

  &--cart {
    &.row {
      padding-bottom: .2rem;
    }
    .detail {
      color: $text-muted;
      font-weight: 700;
    }

    .discount {
      color: $success-color;
    }
  }

  .amount {
    text-align: right;
    font-weight: 600;
  }
}
