@use '../../../assets/scss/variables.scss';

$form-border: #E6EEF2;

.coupon {
  padding-top: 1rem;
  border-top: 1px solid $form-border;
  margin-top: 1rem;

  a {
    cursor: pointer;
  }

  &-input {
    display: inline-block;
    max-width: 250px;
  }

  &-btn {
    padding: 9px 20px !important;
    position: relative;
    top: -2px;
    left: -3px;
    border-radius: 0px 4px 4px 0px;
    font-size: 14px;
  }

  .txt-remove {
    display: inline;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .coupon {
    &-input {
      max-width: 200px;
    }
  
    &-btn {
      padding: 4px 10px !important;
      top:-1px;
    }
  }
}
