@use "../../assets/scss/variables";

$text-muted: #6C7B7F;
$lighter-text: #4A4A4A;
$primary-color: #31B8DA;

.payment-form {
  .stripe-logo {
    float:right;
    margin-top:10px;
    margin-right:1px;
    margin-bottom:2em;
    background-image: url("../../assets/images/powered_by_stripe.svg");
    display:block;
    height:24px;
    width:110px;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: top right;
  }

  .resetSelectStyle button {
    -webkit-appearance: none;
    appearance: none;
    border: 0px;
    background-color: transparent;
    padding: 0px;
    text-align: left;
  }

  span.card-icons {
    float:right;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 3px;
      margin-bottom: 1px;
    }
  }

  span.cc-icon {
    width: 26px;
    height: 16px;
    display: inline-block;
    margin-right: 2px;
    background-repeat: no-repeat;
    &.elo {
      background-image: url("../../assets/images/elo.png");
    }
    &.hipercard {
      background-image: url("../../assets/images/hipercard.png");
    }
    &.aura {
      background-image: url("../../assets/images/aura.png");
    }
  }

  .cvv-form-group label:before {
    font-family: "Font Awesome\ 5 Free", sans-serif;
    content: "\f09d";
    position: absolute;
    top: 2.6rem;
    left: 1.8rem;
    opacity: .5;
    font-weight: 300;
  }

  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem !important;
    line-height: 2rem;
    font-weight: 600;
  }

  .nav {
    margin: 0 0 2rem -1rem;
  }

  a.nav-link {
    padding-bottom: 0;
    font-weight: 600;
    color: $text-muted;
    cursor: pointer;

    span {
      display: block;
      padding-bottom: .5rem;
    }
  }

  .nav-link.active {
    color: $lighter-text;

    span {
      padding-bottom: calc(.5rem - 2px);
      border-bottom: 2px solid $primary-color;
    }
  }

  .alert {
    margin: 2rem 0 0;
  }
}
