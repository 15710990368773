@use '../../../assets/scss/variables.scss';

.course-assign {
  padding-top:1rem;

  .form-group-status {
    float: right;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    transition: all .25s ease-in-out;
  }

  &--selected-users {

    .row {
      padding-bottom: 10px;
      position: relative;
    }
  }

  $primary-color: #31B8DA;
  &--remaining-seat {
    color: $primary-color;
  }

  &--user {
    padding-left: 0;
  }

  &--user-name {
    font-size: 20px;
  }

  $text-muted: #6C7B7F;
  &--user-mail {
    color: $text-muted;
  }

  &--remove {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .dropdown-item {
    color: #16181B;
    &.active {
      background: rgba(49, 184, 218, 0.4);
    }
    &:hover {
      background: rgba(49, 184, 218, 0.1);
    }
  }


}
