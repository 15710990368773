.seats-counter {
  padding-top: 30px;
  display: table;
  .counter {
    float: right;
    display: flex;
    min-width: 160px;
  }

  &-title {
    display: table-cell;
    vertical-align: middle;
    padding-right: 8px;
    &--big {
      font-weight: 600;
      align-items: center;
      line-height: normal;
      font-size: 20px;
    }

    &--small {
      line-height: normal;
      align-items: center;
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 960px) {
  .seats-counter {
    .counter {
      float: right;
      min-width: 100px;
      .counter-action {
        width:30px;
        button {
          padding: 8px;
          width:30px;
        }
      }
      .counter--count {
        padding:12px;
      }
    }
  }
}
