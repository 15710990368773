@use "sass:color";
@use 'variables.scss';

::selection { background: rgba(color.red(variables.$primary-color), color.green(variables.$primary-color), color.blue(variables.$primary-color), 0.4); }

::placeholder {
    color: color.adjust(variables.$body-color, $lightness: 50%)!important;
}
html {
  font-size:16px;
}
body {
  font: 100% "Muli", sans-serif;
  font-weight:400;
  color: variables.$body-color;
  background:variables.$main-bg-color;
}
h1, h2, h3, h4 {
	font: 100% variables.$font-stack;
}
h1 {
	color:variables.$secondary-color;
    font-weight: 400;
    font-size:3rem;
    margin-bottom:1.5rem;
}
h2 {
	color:variables.$secondary-color;
    font-size:2.25rem;
    font-weight: 400;
    margin-bottom:1.5rem;
}
h3 {
	color:variables.$secondary-color;
    font-size:2rem;
    font-weight: 400;
    margin-bottom:1.5rem;
}
p {
	font-size:1rem;
	line-height: 1.5em;
	margin-bottom:1.2rem;
	strong {
		font-weight: 600;
	}
}
#main {
  height:100vh;
}
label {
	cursor: pointer;
	font-weight:600;
}

$primary-color: #31B8DA;
.bg-success {
	background:$primary-color!important;
}
.bg-warning {
	background:variables.$warning-color!important;
}
.bg-danger {
	background:variables.$danger-color!important;
}
.btn-link {
	color:$primary-color!important;
	&.danger {
		color:variables.$danger-color!important;
	}
}

.center {
  margin: 0 auto;
}

.block-ui-overlay {
	background:variables.$main-bg-color;
}
.bg-primary {
	background-color:$primary-color!important;
}
.btn, a {
	svg {
    	margin:0 .35rem .15rem;
    	vertical-align:middle;
    }
}
.btn {
	border: 0px solid transparent;
	padding: .75rem 1.5rem;
	box-shadow: 2px 4px 14px rgba(0,0,0,.1), 1px 1px 5px rgba(0,0,0,.08);
	text-shadow: 1px 1px 1px rgba(0,0,0,0.07);

	svg {
		margin-left:0;
	}
	&.btn-primary {
	    background-color: $primary-color;
	    border-color: $primary-color;
	    &:hover {
			background-color: color.adjust( $primary-color, $lightness: -10% );
		}
	}

	&.btn-secondary {
	    background-color: variables.$secondary-color;
	    border-color: variables.$secondary-color;
	    &:hover {
			background-color: color.adjust( variables.$secondary-color, $lightness: -15% );
		}
	}

	&.btn-success {
	    background-color: variables.$success-color;
	    border-color: variables.$success-color;
	    &:hover {
			background-color: color.adjust( variables.$success-color, $lightness: -15% );
		}
	}

	&.btn-warning {
	    background-color: variables.$warning-color;
	    border-color: variables.$warning-color;
	    &:hover {
			background-color: color.adjust( variables.$warning-color, $lightness: -15% );
		}
	}

	&.btn-danger {
	    background-color: variables.$danger-color;
	    border-color: variables.$danger-color;
	    &:hover {
			background-color: color.adjust( variables.$danger-color, $lightness: -15% );
		}
	}

	&.btn-xl {
		padding: 1rem 3rem;
	    font-size: 1.25rem;
	    line-height: 1.5;

	    svg {
	    	margin-right:.35rem;
	    }
	}

	&.top-action {
		margin-top:1rem;
	}
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
  border-color:color.adjust( variables.$success-color, $lightness: 20% ) !important;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: color.adjust( $primary-color, $lightness: -20% );
    border-color: color.adjust( $primary-color, $lightness: -20% );
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    background-color: color.adjust( variables.$secondary-color, $lightness: -20% );
    border-color: color.adjust( variables.$secondary-color, $lightness: -20% );
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    background-color: color.adjust( variables.$success-color, $lightness: -20% );
    border-color: color.adjust( variables.$success-color, $lightness: -20% );
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    background-color: color.adjust( variables.$warning-color, $lightness: -20% );
    border-color: color.adjust( variables.$warning-color, $lightness: -20% );
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    background-color: color.adjust( variables.$danger-color, $lightness: -20% );
    border-color: color.adjust( variables.$danger-color, $lightness: -20% );
}

.btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(color.red(variables.$secondary-color), color.green(variables.$secondary-color), color.blue(variables.$secondary-color), 0.5);
}
.plain-link {
  background:transparent;
  border:0px solid transparent;
  color:$primary-color;
  padding:0;
  &:hover {
    text-decoration: underline;
    cursor:pointer;
  } 
}

a {
  color:$primary-color;

  &:hover{
  	color: color.adjust($primary-color, $lightness: -20%);
  }
}
input[type="text"], input[type="password"], textarea, select {
	&.form-control {
		border: 1px solid variables.$form-border;
		box-shadow: inset 1px 1px 5px rgba(0,0,0,0.07);
		&:focus{
			border-color: rgba(color.red($primary-color), color.green($primary-color), color.blue($primary-color), 0.5);
			box-shadow:0 0 0 0.2rem rgba(color.red($primary-color), color.green($primary-color), color.blue($primary-color), 0.25)
    }
	}
	&.form-control.is-invalid {
	  border-color:variables.$danger-color;
	}
}
.placeholder {
	opacity: 0.5;
}
input[type="checkbox"] {
  height: 20px;
  width: 20px;
  &:focus, &:active, &.is-invalid, &.is-valid {
    padding:0;
  }
}

.txt {
  &-grey {
    color: #6C7B7F;
  }

  &-sm {
    font-size: 16px;
    line-height: 1.3em;
  }

  &-lg {
    font-size: 20px;
    line-height: 1.3em;
  }

  &-center {
    text-align: center;
  }
}

.m-t-sm {
  margin-top: 10px;
  font-weight: normal;
}

.link {
  color: $primary-color !important;
  text-decoration: underline !important;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

section#main {
    padding:2rem 0 2rem 0;
    background:variables.$main-bg-color;
    flex-direction: column;

	.loader {
    width:100%;

    &--processing {
      .block-ui-overlay {
        opacity: 0.2;
      }
    }
  }

  .divider {
    margin-top: 1.5rem;
    border-top: 1px solid #E6EEF2;
  }
  
  .app-logo {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 15px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 20px;
  }

    .card {
        background: white;
        border-radius: .5rem;
        margin-bottom: 1rem;
        box-shadow: 0 2px 5px rgba(0,0,0, 0.1);
        border: 1px solid variables.$border-color;
        padding: 1.5rem;

        h3 {
            display:inline-block;
            font-size:1.25rem;
            font-weight:600;
        }

        footer {
          padding:1rem 0 0;
        }

        &.disabled {
            background-color:color.adjust(variables.$main-bg-color,$lightness: -3);

            h3, dl {
                opacity:.5;
            }
            button {
                opacity:1;
            }
        }
    }

    .course-details {
      margin-bottom:3rem;
      h3 {
        margin-bottom:1rem;
      }
      .author {
        color:color.adjust(variables.$body-color, $lightness: 30%);
      }
      .description, .description p {
        font-size:1.25rem;
        line-height:1.875rem;
        color:variables.$lighter-text;
      }
      .currency {
        font-size:1.25rem;
      }
      .price {
        float:right;
        font-size:1.25rem;
        font-weight:600;
      }
      .user-details {
        border-top:1px solid variables.$form-border;
        padding:1.25rem 0 0;
        margin-top:1rem;
        h3 {
          display:block;
        }
        h4 {
          font-size:1.25rem;
          margin-top:1.5rem;
        }
        .avatar-thumb, .gen-avatar {
          width:45px;
          height:45px;
          border-radius:50%;
          margin:0 1rem 0 0;
        }

        .gen-avatar {
          float: left;
          background: variables.$secondary-color;
          text-align: center;
          display: inline-block;
          color: white;
          line-height: 45px;
          font-weight: 600;
          font-size: 1.3rem;
        }

      }
    }

    .app-login {
      background-image: url("../images/logo.png");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 30px auto;

      h2 {
        font-size:1.125rem;
        font-weight:600;
        border: 1px solid variables.$border-color;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom:1px solid white;
        display:inline-block;
        padding:1rem 2rem;
        margin-bottom:0;
        z-index:1;
      }
      form {
        padding: 4rem 1rem 1rem 1rem;
        border-top: 1px solid variables.$border-color;
        z-index:0;
        margin-top:-1px;
      }
      .nav-link {
        cursor: pointer;
        border: 0px;
        background-color:#F1F6F9;
      }
      .nav-link.active {
        background-color:white;
      }
      &.nav-tabs {
        border-bottom: none;
        .nav-item {
          margin-bottom:0;
        }
      }
    }
    form.login-form .form-check label {
      font-weight:400;
      margin-left:.5rem;
    }

    .cc-payment {
      span.card-icons {
        float:right;

        svg {
          width: auto;
          height: 18px;
          margin-right: 3px;
          margin-bottom: 1px;
        }
      }
      span.cc-icon {
        width:26px;
        height:16px;
        display:inline-block;
        margin-right:2px;
        background-repeat: no-repeat;
        &.elo {
          background-image: url("../images/elo.png");
        }
        &.hipercard {
          background-image: url("../images/hipercard.png");
        }
        &.aura {
          background-image: url("../images/aura.png");
        }
      }

      h3 {
        &.ebanx-heading {
          background-image: url("../images/ebanx-logo.png");
          background-repeat: no-repeat;
          background-position: right center;
          background-size: 85px 16px;
        }
        font-size:1.5rem;
        font-weight:600;
      }

      form {
        .billing-details {
          border-bottom:1px solid variables.$border-color;
          padding-bottom:2rem;
          margin-bottom:2rem;
        }
        label {
          display:block;
        }
        .form-group.address {
          input {
            display:inline-block;
            margin-bottom:1rem;
          }
          .invalid-feedback {
            margin-top:-1rem;
          }
        }
        select {
          display:inline-block;
          margin-bottom:1rem;
        }
      }

      .ebanx-form {
        border:1px solid variables.$border-color;
        padding:2rem 2rem 1rem;
        background: #F1F6F9;
        margin-top:3rem;
        border-radius:0.5rem;
        margin-bottom:2rem;

        h5 {
          font-size:1rem;
          text-transform: uppercase;
          color: color.adjust(variables.$body-color, $lightness: 30%);
        }

        label {
          margin-top:1rem;
        }

        input {
          border: 1px solid color.adjust(variables.$form-border, $lightness: -5%)
        }

        input.is-invalid {
          border-color:variables.$danger-color;
        }

        input.is-valid {
          border-color:color.adjust(variables.$success-color, $lightness: 20%);
        }

        input[name="ccExpMonth"], input[name="ccExpYear"] {
          width:6rem;
          display:inline-block;
          margin-right:1rem;
        }

        input[name="ccCVV"] {
          width: 6rem;
          padding-left: 2rem;
        }
        .cc {
          display: inline-block;
          max-width: 50%;
        }
        .cvv-form-group label {
          position:relative;
        }
        .cvv-form-group label:before {
          font-family: "Font Awesome\ 5 Free", sans-serif;
          content: "\f09d";
          position:absolute;
          top:2.6rem;
          left:.6rem;
          opacity:.5;
          font-weight:300;
        }
        .cvv-form-group {
          max-width:40%;
        }
      }
    }
}
span.required {
	color:variables.$danger-color;
}
.loading-bullet {
	color:$primary-color;
}
.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}
.ball-pulse > div {
	background:$primary-color;
	width: 10px;
    height: 10px;
}
.modal-content {
	border:none;
	.modal-body {
		padding:1.5rem;
	}
	.modal-header {
		padding:1.5rem 1.5rem 0.75rem;
		background-color:white;
		color:variables.$body-color;
		border-bottom: 1px solid rgba(variables.$border-color, 0.08);
		.close {
			text-shadow:none;
		}
  }
}
.tab-content {
  padding: 1em 1.5em;
  background: white;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
@media screen and (min-width: 960px) {

}
@media screen and (max-width: 960px) {
    html {
        font-size:14px;
    }
    input[type="checkbox"] {
      height: 16px;
      width: 16px;
    }
}
@media screen and (max-width: 600px) {
    html {
        font-size:12px;
    }
}
