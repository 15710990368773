@use '../../../assets/scss/variables.scss';

$body-color:rgb(55, 75, 80);
$form-border: #E6EEF2;
$border-color: #E3EEF3;

.content-detail {
  padding-bottom: 15px;
  .course-preview {
    margin-bottom:3rem;
    padding-bottom: 10px;

    h3 {
      margin-bottom: 0.5rem;
    }

    .message {
      h3 {
        font-size: 25px !important;
      }

      div {
        color: #8C9FA4;
      }
    }

    .price {
      font-weight: 600;
    }

    .author {
      color: lighten($body-color, 30%);
    }

    .message {
      text-align: center;
      padding-bottom: 50px;
    }

    .btn-start {
      width: 100%;
      margin-top: 20px;
      font-weight: bold;
    }

    .description {
      padding-top:1rem;
      p {
        font-size:1.1rem;
      }
    }

    .header-info {
      display: flex;
      border-bottom:1px solid $form-border;

      &--left {
        div {
          display: inline-block;
        }
        .header-info-subtitle {
          font-size: 16px;
          line-height: 20px;
        }
      }

      &--right {
        text-align: right;
        flex: auto;
        .header-info-subtitle {
          font-size: 13px;
          line-height: 16px;
        }
      }

      &-image {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        margin-right: 15px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &-title {
        font-size: 20px;
        margin-bottom: 10px;
      }

      &-subtitle {
        position: relative;
        top: -4px;
      }

      &-right-text {
        text-align: right;
        vertical-align: middle;
      }
    }

    .user-info {
      padding: 1rem 0 0;
      h4 {
        font-size: 1.25rem;
        margin-top: 1.5rem;
      }
    }
  }
}

.explore-btn {
  max-width: 570px;
  margin: 0 auto;
  padding-top: 10px;
  border-top: 1px solid $border-color;
}
