@use "../../../node_modules/loaders.css/src/variables";
@use "sass:meta";
@use "~loaders.css/src/animations/ball-pulse.scss";

@import url('https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,600,600i,700,700i');
@import url('https://use.fontawesome.com/releases/v5.0.10/css/all.css');
@include meta.load-css('~loaders.css/src/animations/ball-pulse.scss');

$font-stack: 'Muli', sans-serif;
$body-color:rgb(55, 75, 80);
$lighter-text: #4A4A4A;
$main-bg-color: #F1F6F9;
$primary-color: #31B8DA;

.ball-pulse {
  background-color: $primary-color;
}
$secondary-color: #36464B;
$success-color: #51C133;
$warning-color: #F6941D;
$danger-color: #DA3131;
$border-color: #E3EEF3;
$form-border: #E6EEF2;
$text-muted: #6C7B7F;
