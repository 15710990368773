@use '../../../assets/scss/variables.scss';

$border-color: #E3EEF3;

.event-session {
  font-size: 16px;
  color: #36474B;
  font-weight: 500;
  padding: 15px 0px;
  border-bottom: 1px solid $border-color;

  &-item {
    position: relative;
    padding-left: 25px;
    
    &-location {
      margin-bottom: 5px;
    }

    &-icon {
      position: absolute;
      left: 0px;
      top: 2px;
      color: #B2CDD5;
    }
  }
}
